import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import messages from './i18n/messages';
import store from './store/store'
import axios from 'axios';
import { createI18n } from 'vue-i18n'
import VueSignaturePad from 'vue-signature-pad';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'maz-ui/css/main.css'

import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

// Use plugin defaults (optional)


// import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from "@sentry/tracing";
// import packageInfo from '../package.json';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(function (config: any) {
  // config.url = config.url;

  let user: any = localStorage.getItem('user');

  if (user != null) {
    user = JSON.parse(user);
    const token = user.token;
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, function (err) {
  return Promise.reject(err);
});

const i18n = createI18n({
  locale: 'es-ca',
  messages
})

const app = createApp(App)
  .use(store)
  .use(i18n)
  .use(setupCalendar, {})
  .use(IonicVue)
  .use(VueSignaturePad)
  .use(VueSweetalert2);

app.component('VCalendar', Calendar);
app.component('VDatePicker', DatePicker);

app.config.globalProperties.$filters = {
  toCurrency(value: any) {
    if (typeof value !== "number") {
      return value;
    }
    const formatter = new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR'
    });
    return formatter.format(value);
  },
  translated(value: any) {
    const locale = i18n.global.locale === 'es-ca' ? 'ca' : i18n.global.locale;
    return value[locale] ? value[locale] : value['ca'];
  }
}

// Sentry.init({
//   app,
//   dsn: "https://c65c8f66b7e34cc581917086aaf37519@o1300104.ingest.sentry.io/6534130",
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "lloguer.motorlescala.com", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

app.use(router);

router.isReady().then(() => {
  app.mount('#app');
});