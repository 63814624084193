import store from "../../store/store";

export default function auth(to: any, from: any, next: any) {
    if (!store.getters.isAuthenticated) {
        return next({
            path: '/login',
            params: { nextUrl: to.fullPath }
        })
    } else {
        return next()
    }
}
