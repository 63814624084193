import Vue from 'vue'
import Vuex from 'vuex'

export default new Vuex.Store({
    state: {
        user: localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')??'{}') : null
    },
    mutations: {
        setUser(state, user) {
            localStorage.setItem('user', JSON.stringify(user));
            state.user = user;
        }
    },
    getters: {
        isAuthenticated: state => {
            return state.user !== null
        },
        getUser: state => {
            return state.user;
        }
    }
})
