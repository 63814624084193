import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import auth from "./middleware/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/reserves'
  },
  {
    path: '/login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/tabs/',
    component: TabsPage,
    beforeEnter: auth,
    children: [
      {
        path: '',
        redirect: '/tabs/reserves'
      },
      {
        path: 'reserves',
        name: 'reserves',
        component: () => import('@/views/Reserves.vue')
      },
      {
        path: 'vehicles',
        component: () => import('@/views/Vehicles.vue')
      },
      {
        path: 'configuracio',
        component: () => import('@/views/Configuracio.vue')
      }
    ]
  },
  {
    path: '/public-booking',
    name: 'public-booking',
    component: () => import('@/views/CrearReservaMultiple.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
