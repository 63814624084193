<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="tab1" href="/tabs/reserves">
          <ion-icon :icon="calendar" />
          <ion-label>Reserves</ion-label>
        </ion-tab-button>
          
        <!-- <ion-tab-button tab="vehicles" href="/tabs/vehicles">
          <ion-icon :icon="car" />
          <ion-label>Vehicles</ion-label>
        </ion-tab-button> -->
        
        <ion-tab-button tab="tab3" href="/tabs/configuracio" v-if="$store.getters.getUser.role === 'admin'">
          <ion-icon :icon="cog" />
          <ion-label>Configuració</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { ellipse, cog, car, calendar } from 'ionicons/icons';

export default defineComponent({
  name: 'TabsPage',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      ellipse, 
      cog, 
      car,
      calendar,
    }
  }
});
</script>
