import ca from './es-ca.json'
import es from './es.json'
import fr from './fr.json'
import en from './en.json'

export default {
    'es-ca': ca,
    es: es,
    fr: fr,
    en: en,
}
